(function () {

    $(function () {

        $(document).ready(function () {
            $(".movie-play").modalVideo({channel:'vimeo'});

            //menu
            $('.menu-trigger').click(function () {
                $('.hamburger').toggleClass('active');
                $('header').toggleClass('opened');
                $('header .buttons').animate({height: 'toggle'});
            });


            //smooth-scroll
            $('.smooth-scroll').on('click', function (e) {
                e.preventDefault();
                var positionTop = $($(this).attr('href')).offset().top - ($('.header').height() + 100);
                $('html, body').animate({
                    scrollTop: positionTop
                }, 500);
            });

            $('a[data-toggle="pill"]').on('shown.bs.tab', function (e) {
                var targetTab = this.href.split('#');
                $('.nav-link').filter('[href="#'+targetTab[1]+'"]').tab('show');
                $('.header a[data-toggle="pill"]').removeClass('active');
                $('#poznaj-szczegoly a[data-toggle="pill"]').removeClass('active');

                var positionTop = $('#szczegoly').offset().top - ($('.header').height() + 100);
                $('html, body').animate({
                    scrollTop: positionTop
                }, 500);
            });

            $('.scroll-to-accordion').click(function (e) {
                var tabId = $(this).data('targettab');
                var accordionId = $(this).data('targetaccordion');

                $('#' + tabId).tab('show');
                $("#" + accordionId).collapse("show");

                e.preventDefault();
            })
        });
    });
}(jQuery));
